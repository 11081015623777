<template lang="pug">
    .layout-general
        form.form-general.card-bg-theme.p-6.rounded.shadow(@submit="submit($event)")
            .form-general__header
                h2 {{ page_title }}
                router-link(to="/features")
                    b-button(text="Kembali" text_icon_left="chevron-left" type="button")
            .form-general__body
                .grid.grid-cols-11.gap-8.mb-16
                    .col-span-4.pr-12
                        h2.text-xl.font-bold.mb-2 Informasi Fitur
                        p.text-gray-400 Pastikan fitur diisi sesuai dengan kebutuhan peran.
                    .col-span-7
                        .field
                            label.field-label Nama Fitur
                            input.field-style(placeholder="Name" type="text" v-model="model.name")
                            span.field-message.text-red-500(v-if="$v.model.name.$error") Nama Fitur wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_name !== ''") {{ error_name }}

                        .field
                            label.field-label Label
                            input.field-style(placeholder="Label" type="text" v-model="model.label")
                            span.field-message.text-red-500(v-if="$v.model.label.$error") Label wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_label !== ''") {{ error_label }}

                        .field
                            label.field-label Deskripsi
                            textarea.field-style(placeholder="Tulis Deskripsi disini..." type="text" v-model="model.description" rows="5")
                            //- span.field-message.text-red-500(v-if="$v.model.description.$error") Deskripsi wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_description !== ''") {{ error_description }}

            .form-general__footer
                b-button(text="Reset" type="button" @click.native="reset()")
                b-button(text="Simpan" kind="primary" type="submit")

</template>

<script>
import { mapState } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'GeneralFeaturesForm',
    components: {
        // 
    },
    data() {
        return {
            route_name: '',

            model: {
                name: '',
                label: '',
                description: '',
            },

            error_name: '',
            error_label: '',
            error_description: '',
        };
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255),
            },
            label: {
                required,
                maxLength: maxLength(255)
            },
            description: {
                // required,
            },
        },
    },
    computed: {
        ...mapState('features', ['detail']),
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Ubah Fitur';
            if (this.route_name === 'GeneralFeaturesCreate') {
                title = 'Tambah Fitur';
            }
            return title;
        },
    },
    watch: {
        id() {},
        page_title() {},
        // form data
        detail(newData) {
            // console.log(newData)
            this.model.name = (newData.name ? newData.name : '');
            this.model.label = (newData.label ? newData.label : '');
            this.model.description = (newData.description ? newData.description : '');
        },
        'model.label': function(newData) {
            if (newData !== '' && newData !== null) {
                this.model.label = newData.replace(/\s+/g, '-');
            }
        },
    },
    methods: {
        setEmptyModel(all=true) {
            for (const key in Object.keys(this.model)) {
                if (all === true) {
                    this.model[Object.keys(this.model)[key]] = '';
                }
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_label = '';
                this.error_description = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = errors.name ? errors.name : '';
                this.error_label = errors.label ? errors.label : '';
                this.error_description = errors.description ? errors.description : '';
            }
        },
        async reset(direct=false) {
            if (direct === true) {
                // this.setEmptyModel(false);
                this.setEmptyModel(true);
                // reset vuelidate
                this.$nextTick(() => {
                    this.$v.$reset();
                });
                this.setErrors(true);
                // window.scrollTo(0,0);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda benar-benar ingin mengosongkan semua data yang sudah diisi?',
                    // text: id,
                    confirmButtonText: 'ya',
                    confirmButtonColor: '#DD6B55',
                    cancelButtonText: 'Tidak',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                        // if (this.route_name === 'GeneralProfilePage') {
                        //     this.setEmptyModel(false);
                        //     // reset vuelidate
                        //     this.$nextTick(() => { this.$v.$reset(); });
                        //     this.setErrors(true);
                        //     window.scrollTo(0,0);
                        // } else {
                            this.getData();
                        // }
                        }
                    });
            }
        },
        getData(update=false) {
            this.reset(true);

            if (this.route_name === 'GeneralFeaturesEdit') {
                Event.$emit('showLoading', true);

                // this.$store.commit('features/setDetail', this.decodeDetailData());
                // // temp
                // Event.$emit('showLoading', false);

                this.$store.dispatch('features/getDetail', this.id)
                    .then((resp) => {
                        if (resp === 'success') {
                            Event.$emit('showLoading', false);
                        } else {
                            Event.$emit('error');
                        }
                    });
            } else {
                // if (this.route_name === 'GeneralFeaturesCreate')
                this.$store.commit('features/setDetail', {});
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (const key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes('$')) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0, 0);
                return;
            }

            Event.$emit('showLoading', true);
            let model_data = this.model;
            // model_data.gatra = JSON.stringify(model_data.gatra);
            // console.log(model_data);
            model_data = Object.keys(model_data).map((key) => model_data[key]);

            if (this.route_name === 'GeneralFeaturesCreate') {
                this.$store.dispatch('features/create', model_data)
                    .then((resp) => {
                        if (resp.result === 'success') {
                            if (save_continue === false) {
                                this.$router.push('/features');
                            } else {
                                this.$router.push('/features/'+ resp.data.data.id +'/edit');
                            }

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Fitur berhasil dibuat',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message && resp.data.message.errors) {
                            // this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                                Event.$emit('showLoading', false);
                                window.scrollTo(0, 0);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });

            // GeneralFeaturesEdit
            } else {
                model_data = [this.id].concat(model_data);

                this.$store.dispatch('features/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/features');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Fitur berhasil diperbarui',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0, 0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });
            }
        },
    },
    created() {
        //
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.route_name = to.name;
            if (to.name === 'GeneralFeaturesCreate' || to.name === 'GeneralFeaturesEdit') {
                vm.getData();
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        this.route_name = '';
        this.$store.commit('features/setDetail', {});
        next();
    },
    mounted() {
        // this.getData();
    },
};
</script>
